import { createSlice } from "@reduxjs/toolkit"
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { auth } from "../../firebase"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: {},
  },
  reducers: {
    setUser(state, action) {
      const payload = action.payload
      return {
        ...state,
        payload,
      }
    },
  },
})

export const { setUser } = authSlice.actions

export default authSlice.reducer
